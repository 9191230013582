<template>
  <div class="page">
    <!--pages/mypushhelpers/mypushhelpers.wxml-->
    <div class="head">
      <div class="left">
        <div>推广人数（人）</div>
        <div class="total">{{totalPeople}}</div>
      </div>
      <img class="tg-img" mode="widthFix" src="~images/tuiguangrenliebiao.png">
    </div>
  
    <div class="tab-box flexcenter">
      <div :class="['item', {active: level === 1}]" @click="toggleTab(1)">
        一级（{{oneLevelTotal}}）
      </div>
      <div :class="['item', {active: level === 2}]" @click="toggleTab(2)">
        二级（{{twoLevelTotal}}）
      </div>
    </div>
  
    <div class="nav" >
      <van-search @input="focusSearch" v-model="searchValue" placeholder="搜索商品名称" />
    </div>
  
   <!-- <div class="col flexcenter">
      <div class="item active flexcenter">
        <div>团队</div>
        <i class="iconfont iconxiangshangxia"></i>
      </div>
      <div class="item flexcenter">
        <text>金额</text>
        <i class="iconfont iconxiangshangxia"></i>
      </div>
      <div class="item flexcenter">
        <text>订单</text>
        <i class="iconfont iconxiangshangxia"></i>
      </div>
    </div> -->
  
    <!--用户列表-->
    <div class="user_list_box">
    
      <div class="user_item"
           v-for="(item, idx) in userList"
           :key="item.uid"
           >
        <div class="user_img"><img :src="item.avatar"></img></div>
        <div class="user_info">
          <div class="name">{{item.nickname}}</div>
          <div class="time">加入时间:{{item.add_time}}</div>
        </div>
        <div class="spread_info">
          <div class="item"><span class="red">{{item.spread_number}}</span>人</div>
          <div class="item">{{item.spread_order}}单</div>
          <div class="item">{{item.spread_price}}元</div>
        </div>
      </div>
  
    </div>
  </div>
</template>

<script>
  export default {
    name: 'mypushhelpers',
    data() {
      return {
        tab: 0,
        level: 1,
        totalPeople:0,
        oneLevelTotal:0,
        twoLevelTotal:0,
        userList:[],
        filter:{},
        page:0,
        totalPage:0,
        totalNumber:0,
      }
    },
    created() {
      this.post(
        this.API.spreadUsersTotal,{}
      ).then(response => {
        this.totalPeople = response.data.total.total
        this.oneLevelTotal = response.data.total.oneLevel
        this.twoLevelTotal = response.data.total.twoLevel
      })

      this.spreadUserList(1,true,this.level);
    },
    methods: {
      toggleTab(idx){
        this.tab = idx
        this.level = +idx
        this.spreadUserList(1,true,this.level);
      },
      /**
       * 搜索
       */
      focusSearch:function(e){
        let filter = this.filter;
        filter['keyword'] = this.searchValue;

        this.filter = filter

        this.spreadUserList(1,true,this.level);
      },
      /**
       * 获取页面数据
       */
      spreadUserList:function(pageNo, override,level){
        let that = this;
        console.log(that.filter);
        this.post(
          this.API.spreadUsers,
          {pageNo:pageNo,pageSize:20,filter:that.filter,level:level}
        ).then(response => {
          let userList = response.data.list.data;
          this.page =  response.data.list.current_page    //当前的页号
          this.totalPage =  response.data.list.last_page    //总页数
          this.totalNumber = response.data.list.total //总条数
          this.userList =  override ? userList : that.userList.concat(userList)
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  /* pages/mypushhelpers/mypushhelpers.wxss */
  .page {
      background-color: #F5F5F5;
  }
  
  .head {
      position: relative;
      padding: 32px;
      background-color: #F8D414;
  }
  .head .left {
      font-size: 24px;
  }
  .head .left .total {
      font-size: 56px;
      font-weight: bold;
  }
  .head .tg-img {
      position: absolute;
      right: 0;
      bottom: -65px;
      width: 141px;
  }
  
  .tab-box {
      justify-content: center;
      height: 88px;
      background-color: #FFFFFF;
      font-size: 24px;
      border-bottom: 1px solid #EEEEEE;
      color: #777777;
  }
  .tab-box .item {
      width: 237px;
      height: 88px;
      line-height: 88px;
      text-align: center;
  }
  .tab-box .active {
      position: relative;
      color: #191919;
      font-weight: bold;
  }
  .tab-box .active:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -48px;
      width: 96px;
      height: 8px;
      border-radius: 50px 50px 0 0;
      background-color: #F8D414;
  }
  
  .van-search {
      padding-top: 10px!important;
  }
  .van-search__content {
      border-radius: 44px!important;
  }
  
  .col {
      justify-content: space-between;
      padding: 0 32px;
      height: 86px;
      background-color: #FFFFFF;
      font-size: 28px;
  }
  .col .item {
      justify-content: center;
      width: 130px;
      text-align: center;
  }
  .col .active {
      font-weight: bold;
  }
  .col .item .iconfont {
      margin: 10px;
      font-size: 32px;
  }
  .user_list_box {}
  .user_list_box .user_item {
      display: flex;
      padding:10px 16px;
      padding-top:16px;
      background-color:#FFFFFF;
      border-top:1px solid #eeeeee;
  }
  .user_item .user_img {
      margin-right: 20px;
  }
  .user_item .user_img img {
      width: 90px;
      height:90px;
      background-color:#eeeeee;
      border-radius: 90px;
      border:2px solid #191919;
  }
  .user_item .user_info .name {
      line-height: 48px;
      font-size:28px;
      color:#191919;
  }
  .user_item .user_info .time {
      font-size:24px;
      color:#777777;
  }
  .spread_info {
      font-size:22px;
      align-content: flex-end;
      margin-left: auto;
	  width:120px;
  }
  .spread_info .item .red {
      color:#FF0000;
  }

</style>
